/**
 * @file standalone/sd_stores.scss
 *
 * \brief Library file - shared styles for stardust store locator component
 *
 */

@import '../theme-bootstrap';

@mixin heading-override {
  text-transform: capitalize;
  color: $black;
  @include breakpoint($medium-up) {
    font-size: 16px;
    line-height: get-line-height(16px, 24px);
  }
  @include breakpoint($landscape-up) {
    font-size: 20px;
    line-height: get-line-height(20px, 32px);
  }
}

@mixin link-override {
  font-size: 14px;
  @include breakpoint($medium-up) {
    font-size: 13px;
  }
  @include link-focused-outline;
  span {
    color: $black;
  }
}

.elc-stores {
  .elc-heading--2,
  .elc-heading--3,
  .elc-heading--6 {
    @include title--h2;
    @include heading-override;
  }
  .elc-store-search-title {
    padding-top: 32px;
  }
  .elc-body--2 {
    @include body-text;
    color: $black;
  }
  .elc-button--outlined {
    border-radius: 0;
    .elc-elc-caption {
      font-family: $font--lars;
      color: $black;
      font-size: 14px;
      @include breakpoint($medium-up) {
        font-size: 13px;
      }
    }
  }
  .elc-google-address-input {
    @include title--h3;
    &[name='address'] {
      background-color: $white;
      border-radius: 0;
      padding: 12px 48px;
      color: $black;
    }
  }
  &.elc-delivery-methods {
    [name='address'] {
      background-color: $color-cream;
    }
  }
  .elc-input-iconbox {
    margin-#{$ldirection}: 12px;
    @include breakpoint($medium-up) {
      margin-#{$ldirection}: 20px;
    }
  }
  .elc-clear-input {
    margin-#{$rdirection}: 12px;
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 20px;
    }
  }
  .elc-button--text {
    @include link;
    @include link-override;
    border-radius: 0;
    border-bottom: 1px solid $black;
    &:hover {
      color: $black;
      box-shadow: none;
    }
  }
  a {
    @include link;
    @include link-override;
  }
  .elc-clear-input {
    margin-top: -11px;
    .elc-remove-icon {
      width: 24px;
      height: 24px;
    }
  }
  .elc-store-address {
    .elc-pin-icon {
      width: 20px;
      height: 20px;
      margin: 0 2px;
    }
  }
  .elc-sidebar-layout {
    @include breakpoint($medium-up) {
      width: 35%;
    }
    .elc-sidebar-row-primary {
      padding: 0 20px;
    }
  }
  .elc-landscape-layout {
    @include breakpoint($medium-up) {
      width: 65%;
    }
  }
  .elc-store-locator-filters {
    .elc-sidebar-row-primary {
      border-bottom: none;
    }
  }
  .elc-store-search-result-message-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .elc-store-search-message {
      width: 60%;
      color: $black;
    }
    .elc-stores-open-now-switch-container {
      width: 40%;
      margin-top: 0;
      text-transform: none;
    }
  }
  .elc-switch {
    min-width: 50px;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background-color: $black;
    border: none;
    padding-left: 4px;
    .elc-switch-circle {
      background-color: $white;
      border: none;
    }
    .elc-switch-background {
      background-image: radial-gradient(circle, $color-cream-stroke 10%, transparent 10.01%);
    }
    &[aria-pressed='true'] {
      background-color: $color-cream-stroke;
      .elc-switch-circle {
        transform: translateX(25px);
        &:active {
          background-color: $color-cream-stroke;
        }
      }
    }
  }
  .elc-store-search-result-message {
    .elc-grid-column {
      padding-top: 0;
      padding-bottom: 24px;
    }
  }
  .elc-store-item,
  .elc-store-item-selected {
    .elc-store-line-info {
      padding: 6px 0;
      @include breakpoint($medium-up) {
        padding: 0 0 4px;
      }
    }
    .elc-store {
      padding: 24px 0;
      .elc-store-name {
        padding-bottom: 12px;
      }
    }
    .elc-stores-actions-wrapper {
      margin-top: 16px;
      a:hover {
        box-shadow: none;
      }
      .elc-button--outlined {
        background: transparent;
        border: none;
        box-shadow: 0 1px 0 $black;
        text-transform: capitalize;
        padding: 0;
        margin-#{$rdirection}: 40px;
      }
    }
    .elc-store-hours-toggle-box-wrapper {
      .elc-store-hours-toggle-box-title {
        font-weight: normal;
      }
      .elc-arrow-icon {
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        &.elc-arrow-expanded {
          transform: rotate(270deg);
        }
      }
    }
  }
  .elc-icon {
    background: $color-primary-800;
  }
  .elc-marker-pin-container {
    .elc-pin-icon {
      @include svg-icon-inline-mask('map-maker.svg');
      background: $black;
    }
  }
  .elc-store-hours-toggle-box-wrapper {
    ul {
      padding-top: 12px;
    }
  }
  .elc-inventory-status-call {
    text-transform: capitalize;
  }
  .elc-store-address-link,
  .elc-store-phone-number-link {
    &:hover {
      box-shadow: none;
    }
  }
}

.elc-responsive-modal-mask {
  @include slide-animation('bottom');
  .elc-find-in-store-modal {
    animation: slideFromBottom 0.5s forwards;
    margin-#{$ldirection}: -50%;
    #{$ldirection}: 50%;
    top: auto;
    bottom: auto;
    max-width: 100%;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-cream;
    padding: 0;
    @include breakpoint($medium-up) {
      bottom: 0;
      height: 82%;
      max-height: none;
      &.elc-bopis-modal {
        height: fit-content;
      }
    }
    @media (min-height: 768px) and (orientation: portrait) {
      bottom: 0;
      height: 100%;
    }
    .elc-store-locator-modal-close {
      z-index: 1000;
      padding: 10px;
      #{$rdirection}: 0;
      top: 0;
      background: $color-white;
      background-color: $color-cream;
      .elc-remove-icon {
        width: 20px;
        height: 20px;
      }
    }
    .elc-stores {
      max-width: unset;
      .elc-store-search-box {
        background-color: $color-cream;
      }
      .elc-store-search-message {
        background-color: $color-cream;
      }
    }
    .elc-store-locator-container {
      padding-top: 0;
    }
    .elc-sidebar-layout {
      @include breakpoint($medium-up) {
        width: 35%;
        display: flex;
        flex-direction: column;
        height: 80vh;
        .elc-store-list-wrapper {
          height: unset;
        }
      }
      .elc-store-head-container {
        align-items: baseline;
        flex-direction: row;
      }
    }
    .elc-sidebar-row-primary {
      background-color: $color-cream;
    }
    .elc-mobile-store-search-box {
      background-color: $color-cream;
    }
    .elc-store {
      .elc-store-name {
        line-height: 1.2;
      }
      .elc-store-inventory-status {
        @include breakpoint($medium-up) {
          text-align: $rdirection;
        }
        &::before {
          content: '\25CF  ';
          font-size: 18px;
        }
        white-space: nowrap;
      }
      .elc-inventory-status-available {
        color: $color-valid;
      }
      .elc-inventory-status-call {
        color: $color-notification;
      }
      .elc-inventory-status-notavailable {
        color: $color-error;
      }
    }
    .elc-bopis-modal-product-basic-info {
      background-color: $color-cream;
    }
  }
  &.isOpen {
    .elc-find-in-store-modal {
      animation: slideFromBottom 0.5s forwards;
    }
  }
}

//DELIVERY METHODS
.elc-delivery-methods {
  .elc-zipcode-search {
    .elc-google-address-input {
      padding: 5px 10px;
      font-size: 14px;
      border-width: 0 0 1px;
      &::placeholder {
        color: $black;
      }
    }
  }
}

//BOPIS POPUP
.elc-bopis-modal-mask {
  .elc-bopis-modal {
    #{$ldirection}: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 100%;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color-cream;
    padding: 50px 20px;
    @include breakpoint($medium-up) {
      height: 82%;
      max-height: none;
      max-width: 95%;
      &.elc-bopis-modal {
        height: fit-content;
      }
    }
    @media (min-height: 768px) and (orientation: portrait) {
      bottom: 0;
      height: 100%;
    }
    .elc-store-locator-modal-close {
      z-index: 1000;
      padding: 10px;
      #{$rdirection}: 0;
      top: 0;
      background: $color-white;
      background-color: $color-cream;
      .elc-remove-icon {
        width: 20px;
        height: 20px;
      }
    }
    .elc-stores {
      max-width: unset;
      .elc-store-search-box {
        background-color: $color-cream;
      }
      .elc-store-search-message {
        background-color: $color-cream;
      }
      .elc-store-item {
        .elc-store {
          padding: 20px 0;
        }
      }
    }
    .elc-map-search-box > div:first-of-type {
      width: 100%;
    }
    .elc-store-locator-container {
      padding-top: 0;
    }
    .elc-landscape-layout {
      @include breakpoint($medium-up) {
        width: 55%;
      }
    }
    .elc-sidebar-layout {
      box-shadow: none;
      background-color: $color-cream;
      padding: 0 20px;
      @include breakpoint($medium-up) {
        width: 45%;
        display: flex;
        flex-direction: column;
        height: 80vh;
        .elc-store-list-wrapper {
          height: unset;
        }
      }
      .elc-store-head-container {
        align-items: baseline;
        flex-direction: row;
      }
    }
    .elc-sidebar-row-primary {
      background-color: $color-cream;
      padding: 0;
    }
    .elc-mobile-store-search-box {
      background-color: $color-cream;
    }
    .elc-store {
      .elc-store-name {
        line-height: 1.2;
      }
      .elc-store-inventory-status {
        @include breakpoint($medium-up) {
          text-align: $rdirection;
        }
        &::before {
          content: '\25CF  ';
          font-size: 18px;
        }
        white-space: nowrap;
      }
      .elc-inventory-status-call {
        color: $color-notification;
      }
      .elc-inventory-status-notavailable {
        color: $color-error;
      }
    }
    .elc-bopis-modal-product-basic-info {
      background-color: $color-cream;
    }
  }
}

.info-modal-overlay {
  .info-modal-overlay__steps {
    display: flex; 
    justify-content: center; 
    padding-top: 35px;
    flex-wrap: wrap;
  }
  .info-modal-overlay__step { 
    width: 100%;
    @include breakpoint($landscape-up) {
      padding: 0 50px;
    }
    @include breakpoint($medium-up) {
      width: 30%;
    }
  }
  .info-modal-overlay__step-title {
    min-width: 150px;
  }
}

.elc-stores-bopis-widget {
  .elc-location-icon {
    display: none;
  }
  .elc-info-icon {
    @include svg-icon-inline-mask('information.svg');
  }
  .elc-body--1,
  .elc-body--2 {
    @include body-text2;
  }
  .elc-button--text {
    @include body-text2;
    background-color: $color-cream;
    border-bottom: none;
    text-transform: none;
    padding: 0;
    text-decoration: underline;
    &:hover {
      background-color: $color-cream;
    }
  }
}
